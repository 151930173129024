import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import toTitleCase from '../utils/toTitleCase'
import HomeIcon from '../images/navbarIcon/home.svg'
import ProductIcon from '../images/navbarIcon/product.svg'
import EngineerIcon from '../images/navbarIcon/engineer.svg'
import DesignIcon from '../images/navbarIcon/design.svg'
import HomeActiveIcon from '../images/navbarIcon/home_active.svg'
import ProductActiveIcon from '../images/navbarIcon/product_active.svg'
import EngineerActiveIcon from '../images/navbarIcon/engineer_active.svg'
import DesignActiveIcon from '../images/navbarIcon/design_active.svg'
import styled, { css } from 'styled-components'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

type Props = {
  title: string,
  subItems: Object,
  active: string,
  dropdownOpen: string,
  handleNavbarItemClick?: (string | null) => (SyntheticUIEvent<>) => mixed,
}

const NavBarItemContent = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  height: 40px;
  border-radius: 80px;
  gap: 12px;
  padding: 4px 12px 4px 4px;
`

const NavBarIconWrapper = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #f5efe7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  ${props =>
    props.active &&
    css`
      background: #7e603d;
    `}
`

const NavBarDropdown = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  padding: 4px;
  color: #646464;
  position: absolute;
  z-index: 10;
  top: 45px;
  width: 120%;
  height: fit-content;
  transition: 0.5s ease;
`

const NavBarDropdownContent = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #646464;
  font-weight: 400;
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  &:hover {
    cursor: pointer;
    background: #fff9f0;
    color: #646464;
  }
  &:visited {
    color: #646464;
  }
  transition: 0.5s ease;
`

const NavBarItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  transition: 0.5s ease;
`

const NavBarItemTitle = styled.span`
  color: #646464;
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`

const NavBarItem = ({
  title,
  subItems,
  active,
  dropdownOpen,
  handleNavbarItemClick,
}: Props) => {
  let icon = HomeIcon
  if (title === 'home')
    icon = active || dropdownOpen === title ? HomeActiveIcon : HomeIcon
  if (title === 'product')
    icon = active || dropdownOpen === title ? ProductActiveIcon : ProductIcon
  if (title === 'engineering')
    icon = active || dropdownOpen === title ? EngineerActiveIcon : EngineerIcon
  if (title === 'design')
    icon = active || dropdownOpen === title ? DesignActiveIcon : DesignIcon
  const handleClick = (event: SyntheticUIEvent<>) => {
    event.preventDefault()
    if (!handleNavbarItemClick) return
    if (dropdownOpen === title) {
      handleNavbarItemClick(null)
    } else {
      handleNavbarItemClick(title)
    }
  }
  return (
    <NavBarItemWrapper>
      <NavBarItemContent onClick={handleClick}>
        {icon && (
          <NavBarIconWrapper
            src={icon}
            width={16}
            height={16}
            alt={title}
            active={active || dropdownOpen === title}
          />
        )}
        <NavBarItemTitle>{toTitleCase(title)}</NavBarItemTitle>
        {subItems?.length && (
          <FontAwesomeIcon
            icon={faChevronDown}
            color="#646464"
            width={16}
            height={16}
          />
        )}
      </NavBarItemContent>
      {dropdownOpen !== null && dropdownOpen === title && subItems?.length && (
        <NavBarDropdown>
          {subItems.map(subItem => (
            <NavBarDropdownContent
              key={subItem.node.id}
              href={subItem.node.frontmatter.path}
            >
              {subItem.node.frontmatter.sidebarTitle}
              <FontAwesomeIcon
                icon={faArrowRight}
                color="#646464"
                width={24}
                height={24}
              />
            </NavBarDropdownContent>
          ))}
        </NavBarDropdown>
      )}
    </NavBarItemWrapper>
  )
}

export default NavBarItem

// @flow
import * as React from 'react'
import { Location, redirectTo } from '@reach/router'
import { Helmet } from 'react-helmet'
import logo from '../images/logo.svg'
import NavbarBuilder from './navbarBuilder'
import '../css/monzo-framework.min.css'
import {
  Contain,
  Navbar,
  NavbarIcon,
  NavbarList,
  NavbarScroll,
  BREAKPOINT_MOBILE,
} from './styles'
import styled, { createGlobalStyle } from 'styled-components'
import slackIcon from '../images/slack.svg'
type Props = {
  data: Object,
  children?: React.Node,
}

type State = {
  isNavbarVisible: boolean,
}

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');

  body {
    font-family: 'Manrope', sans-serif;
  }
`

const SlackButton = styled.a`
  position: fixed;
  bottom: 80px;
  right: 80px;
  background: #fff;
  border: none;
  background: transparent;
  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border: none;
  }
  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 10px 80px 80px;
  background: linear-gradient(180deg, #f6f0e7 0%, #ffffff 100%);
`

const NavbarRenderer = ({ isNavbarVisible, data }: Object) =>
  isNavbarVisible ? (
    <Location>
      {({ location }) => (
        <Navbar>
          <NavbarScroll>
            <NavbarIcon src={logo} alt="Growth Logo" />
            <NavbarList main>
              <NavbarBuilder
                data={data}
                location={location}
                redirectTo={redirectTo}
              />
            </NavbarList>
          </NavbarScroll>
        </Navbar>
      )}
    </Location>
  ) : null

class Layout extends React.Component<Props, State> {
  state = { isNavbarVisible: false }

  componentDidMount() {
    this.updateNavbarOnResize()
    window.addEventListener('resize', this.updateNavbarOnResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNavbarOnResize)
  }

  updateNavbarOnResize = () => {
    this.setState({
      isNavbarVisible: window.innerWidth > BREAKPOINT_MOBILE,
    })
  }

  sidebarClickHandler = (event: SyntheticUIEvent<>) => {
    event != null ? event.preventDefault() : null
    this.setState({
      isNavbarVisible: !this.state.isNavbarVisible,
    })
  }

  render() {
    let { children, data } = this.props
    let { isNavbarVisible } = this.state

    return (
      <Contain>
        <GlobalStyles />
        <Helmet>
          <title>Career Framework</title>
        </Helmet>
        <NavbarRenderer
          data={data}
          sidebarClickHandler={this.sidebarClickHandler}
          isNavbarVisible={isNavbarVisible}
        />
        <Main>
          <>{children}</>
        </Main>
        <SlackButton href="slack://user?team=T16AT245P&id=U0447FDC50W">
          <img src={slackIcon} />
        </SlackButton>
      </Contain>
    )
  }
}

export default Layout

// @flow
import React from 'react'
import NavBarItem from './navbarItem'

type NavbarState = {
  activePath: string,
  dropdownOpen: string | null,
}

type NavbarProps = {
  data: Object,
  location: Object,
}

class NavbarBuilder extends React.Component<NavbarProps, NavbarState> {
  constructor(props: NavbarProps) {
    super(props)
    const activePath = props.location.pathname.split('/')[1] || 'home'
    this.state = {
      dropdownOpen: null,
      activePath: activePath,
    }
  }

  handleNavbarItemClick = (val: string | null) => {
    if (val === 'home') window.location.href = '/'
    this.setState({
      ...this.state,
      dropdownOpen: val,
    })
  }

  render() {
    const { activePath, dropdownOpen } = this.state
    const { data } = this.props
    const { allMarkdownRemark } = data
    const { edges } = allMarkdownRemark
    let frameworkGroups = edges
      .map(edge => {
        return edge.node.frontmatter.sidebarGroup
      })
      .filter(edge => !!edge)
    frameworkGroups = Array.from(new Set(frameworkGroups).values())
    const frameworkElement = frameworkGroups.map(group => {
      let obj = {}
      obj['title'] = group
      obj['path'] = group && `/${group}`
      obj['child'] = edges.filter(edge => {
        return edge.node.frontmatter.sidebarGroup === group
      })
      return obj
    })

    return (
      <React.Fragment>
        <NavBarItem
          key={Math.random()}
          title="home"
          active={activePath === 'home'}
          handleNavbarItemClick={this.handleNavbarItemClick}
        />
        {frameworkElement.map(element => (
          <NavBarItem
            key={element.title}
            title={element.title}
            subItems={element.child}
            active={activePath === element.title}
            dropdownOpen={dropdownOpen}
            handleNavbarItemClick={this.handleNavbarItemClick}
          />
        ))}
      </React.Fragment>
    )
  }
}

export default NavbarBuilder

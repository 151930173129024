// @flow
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import PartialNavLink from './partialNavLink'

// Variables
export const PORTRAIT = 'max-aspect-ratio: 13/9'
export const LANDSCAPE = 'min-aspect-ratio: 13/9'
export const BREAKPOINT_MOBILE = 736
export const BREAKPOINT_TABLET = 1024
export const BREAKPOINT_DESKTOP = 1300
export const MIDNIGHT_SKY = '#14233c'
export const LAGOON_BLUE = '#145d8e'
export const GREY_BLUE = '#707b8c'

// Styles
// Error page

export const ErrorPageHeader = styled.h1`
  font-weight: 300;
  font-size: 10rem;
  opacity: 0.4;
`
export const ErrorPageDescription = styled.h2`
  color: #888888;
`

export const ErrorPageLink = styled(Link)`
  border: 1px solid;
`

// Globals

export const Card = styled.div`
  position: relative;
  background: #fefefe;
  border-radius: 8px;
  box-shadow: 0 0 2em 0.1em rgba(0, 0, 0, 0.1);
  padding: 1.3em;
`

export const Title = styled.h1`
  font-weight: 600;
  margin-top: 0;
  font-size: 40px;
  line-height: 48px;
  color: #202020;
`

export const Subtitle = styled.h3`
  font-weight: 600;
  color: ${GREY_BLUE};

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    font-size: 1.8em;
  }

  ${props =>
    props.small &&
    css`
      font-size: 1.6em;

      @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
        font-size: 1.4em;
      }
    `}
`

export const DefaultStyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 500;
  font-size: 1.3em;
  color: #646464 !important;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: inherit;
  margin: 0;
  padding: 4px 0;

  &:hover {
    opacity: 0.7;
    color: ${LAGOON_BLUE};
  }
  &:focus {
    outline: none;
  }
  &.active {
    border-radius: 10px;
    background: #eeefee;
  }
`

export const StyledLink = styled(PartialNavLink)`
  cursor: pointer;
  font-weight: 500;
  font-size: 1.3em;
  color: ${MIDNIGHT_SKY} !important;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: inherit;
  padding: 0.5em 0 0.25em 0.5em;
  margin: 0.5em 0 -0.5em -0.5em;

  &:hover {
    opacity: 0.7;
    color: ${LAGOON_BLUE};
  }
  &:focus {
    outline: none;
  }
  &.active {
    border-radius: 10px;
    background: #eeefee;
  }
`

// Layouts

export const PrimaryView = styled.div`
  height: 100%;
  position: relative;
  padding: 3em 5em;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    padding: 2em 2.5em;
  }

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 5% 1em 2em 1em;
  }
`

export const ScrollbarHeader = styled.div`
  display: flex;
  margin-top: 3em;
  margin-left: 3.5rem;
  margin-bottom: 15px;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-left: 10%;
    margin-bottom: 10px;
  }
`

export const CenteredElement = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 70% !important;

    @media (${LANDSCAPE}) {
      margin-top: 4em;
    }
  }
`

export const Flexbox = styled.div`
  cursor: pointer;
  display: flex;
`

export const FlexboxPush = styled.div`
  margin: 0 0 0 auto;
  cursor: pointer;
`

export const Wrapper = styled.div`
  margin-top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100% !important;
  padding: 80px 120px 0 80px;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
`

export const Content = styled.div`
  background-color: white;
  height: auto;
  width: 70%;
  max-width: 900px;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    .container {
      padding-left: 20px;
    }
  }
`

export const Banner = styled.img`
  width: 100%;
  max-width: 905px;
`

export const Contain = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
`

// Levels

export const LevelsSubtitle = styled(Subtitle)`
  float: right;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    float: left;
    padding-right: 1em;
  }
`

export const LevelsContainer = styled.div`
  margin-top: 0;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  gap: 24px;
`

export const LevelsTextWrapper = styled.div`
  display: flex;
  margin-left: 0;
  margin-top: 0;
  width: 100%;
  gap: 24px;
`

export const LevelsText = styled.h2`
  cursor: pointer;
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
  color: #8d8d8d;

  ${props =>
    props.active &&
    css`
      color: #796142;
      border-bottom: 1px solid #796142;
    `}
`

// Toolbar

export const Toolbar = styled.div`
  display: none;
  width: 0;
  visibility: hidden;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding: 1.5rem 1.2rem 1.5rem;
    display: flex;
    visibility: inherit;
    height: 4.5em;
    width: 100%;
    background: #f7f7f7;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
`

export const ToolbarPushWrapper = styled(FlexboxPush)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ToolbarIcon = styled.img`
  width: auto;
  height: auto;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    max-height: 26.25px;
    max-width: 30px;
  }
`

export const MenuIcon = styled.img`
  width: auto;
  height: auto;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    display: inherit;
    margin-top: 0;
  }
`

// Navbar

export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #f6efe6;
  color: black;
  -webkit-overflow-scrolling: touch;
  min-height: 98px;
  width: 100%;
  min-width: 320px;
  z-index: 10;
  h4 {
    padding-top: 0;
    font-size: 1.3em;
  }

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    width: 100% !important;
    height: 100%;
    align-items: center;
    justify-content: center;
    min-width: 250px;
    transform: none;
  }
`

export const ListElement = styled.li`
  display: flex;
  margin-top: 4px;
`

export const EmojiListItem = styled.span`
  padding-right: 10px;
`

export const NavbarList = styled.ul`
  padding-left: 0;
  z-index: 10;
  margin-top: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`

export const NavbarPaddingSection = styled.div`
  ${props =>
    props.borderedBottom &&
    css`
      padding-bottom: 3rem;
    `}

  ${props =>
    props.borderedTop &&
    css`
      margin-top: 1.5rem;
    `}

  ${props =>
    props.bottom &&
    css`
      padding-bottom: 2rem;
    `}

  ${props =>
    props.top &&
    css`
      margin-top: 3rem;
    `}

  ${props =>
    props.border &&
    css`
      border-bottom: 1px solid #d9d9d9;
    `}
`

export const NavbarScroll = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 80px;
  z-index: 10;
`

export const NavbarIcon = styled.img`
  width: 120px;
  height: 50px;
  z-index: 10;
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    max-height: 39px;
    max-width: 45px;
    min-width: 39px;
  }
`

export const CloseIcon = styled.img`
  display: none;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    display: inherit;
    height: 23px;
    margin-top: 8px;
  }
`

// Description view

export const DescriptionView = styled(PrimaryView)`
  padding-top: 10%;

  @media (min-width: ${BREAKPOINT_MOBILE}px) and (max-width: ${BREAKPOINT_TABLET}px) {
    padding-top: 4em;
    padding-left: 3em;
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    padding-top: 5%;
  }
`

export const DescriptionTitleGroup = styled.div`
  position: relative;
  z-index: 1;
`

export const Description = styled.div`
  position: relative;
  color: #34495e;
  width: 65%;
  z-index: 1;

  @media all and (max-width: ${BREAKPOINT_DESKTOP}px) {
    width: 100%;
  }
  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    margin-bottom: 3em;
    width: 95%;
  }
`

export const DescriptionText = styled.p`
  margin-top: 24px;
  line-height: 1.3em;
  font-weight: 500;
  font-size: 1.5em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    font-size: 1.2em;
    font-weight: 500;
  }
`

// Levelled framework view

export const ExamplesText = styled.p`
  color: ${LAGOON_BLUE};
  font-weight: 600;
  opacity: 1;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    color: ${LAGOON_BLUE};
  }
`

export const FrameworkHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1em;

  @media all and (max-width: ${BREAKPOINT_MOBILE}px) {
    display: block;
  }
`

export const FrameworkTitleGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FrameworkCard = styled(Card)`
  position: relative;
  margin-top: 0;
  margin-bottom: 1em;
`

export const CardContentList = styled.ul`
  margin-top: 1em;
  padding-left: 1em;
  width: 95%;
`

export const CardTitle = styled.p`
  font-weight: 600;
  color: ${MIDNIGHT_SKY};
  margin-top: 0;
  font-size: 1.4rem;
`

export const CardTitleGroup = styled.div`
  margin-top: 0.2em;
`

// Text framework view

export const MarkdownContent = styled.div`
  h2 {
    margin-top: 0.2em;
  }
  ul {
    padding-left: 1em;
  }
  & h2:nth-child(1) {
    margin-top: 0;
    padding-top: 0;
  }
`

export const FrameworkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 70%;
`

export const PageBanner = styled.img`
  margin-top: 24px;
  margin-bottom: 40px;
`

export const LevelHeader = styled.h2`
  color: #796142;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
`

export const RoleTab = styled.div`
  width: fit-content;
  color: #352f26;
  height: 40px;
  border-radius: 100px;
  background: #efe6da;
  display: flex;
  align-items: center;
`

export const RoleTabItem = styled.div`
  &:hover {
    cursor: pointer;
    color: #352f26;
  }
  height: 40px;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 100%;

  ${props =>
    props.active &&
    css`
      background: white;
      border: 1px solid #d9d9d9;
      font-weight: 600;
    `}
`
